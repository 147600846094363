import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import LogoBall from "assets/orion-logo-ball.png";
import LogoText from "assets/orion-always-connected.png";
import MastercomLogo from "assets/Mastercom.png";
import { useLogoQuery } from "contexts";
import { default_logo_query } from "queries";
import { Loading } from ".";

function OptionsPanelLogo() {
    return ( 
      <Box sx={{ display: "flex", alignItems: "center", justifyItems: "center", boxSizing: "unset"}}>
        <img
          src={MastercomLogo}
          height="45px"
          style={{ padding: '3px 0px 0px 3px'}}
          alt="Logo"
        />
      </Box>
    );
  }
export { OptionsPanelLogo }
