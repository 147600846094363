// QueryContext provides a centralized way to manage and execute authenticated GraphQL queries.
// It abstracts the complexity of handling authentication and request management, 
// allowing components to focus on using the data returned by the queries.
//
// The context provides several hooks for some common queries, but in most cases these are 
// delegated to specific context providers which are dependant on the  useQuery hook to retrieve data.

import React, { useState, useEffect, useContext, createContext } from "react";
import auth_config from "../auth_config.json";
import { useAuth0 } from '@auth0/auth0-react';
import { GraphQLClient } from "graphql-request";
// import { useCallback } from 'react';
// import { useLocation } from 'react-router-dom';

/**
 * Returns a promise that resolves after a specified timeout.
 * @param {number} ms - The number of milliseconds to wait before resolving the promise.
 * @returns {Promise} A promise that resolves after the specified timeout.
 */
function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const QueryContext = createContext();

/**
 * Provides a context for executing GraphQL queries with authentication.
 * @param {Object} props - The properties passed to the provider.
 * @param {string} props.endpoint - The GraphQL endpoint to query.
 * @param {React.ReactNode} props.children - The child components that can access the context.
 * @returns {JSX.Element} The provider component.
 */
function QueryContextProvider({ endpoint, children }) {

  /**
   * Fetches data from a GraphQL endpoint with authentication.
   * @param {string} query - The GraphQL query to execute.
   * @param {string} auth_token - The authentication token to use for the request.
   * @param {Object} [query_options={}] - Additional options for the query.
   * @returns {Promise<Object>} The result of the GraphQL query.
   * @throws {Error} If the request times out.
   */
  async function fetch(query, auth_token, query_options = {}) {
    const ac = new AbortController();
    const client = new GraphQLClient(endpoint, { headers: { "Authorization": `Bearer ${auth_token}` }, signal: ac.signal });

    const result = await Promise.race([
      client.request(query, query_options), timeout(40000).then(async () => { ac.abort("Timeout?"); return "timeout"; })
    ]);

    if (result === "timeout") throw new Error("Request Timed Out");
    return result;
  }

  return (
    <QueryContext.Provider value={{ fetch }}>
      {children}
    </QueryContext.Provider>
  )
}

// Previously I was having issues where this function was used
// by multiple functions and the state was getting mixed up.
// I split it into a number of similar functions to avoid this.
// This is a bit messy, and would be nice to clean up.
// 
// I think the concept of Context is to have some kind of global state, 
// which is perhaps NOT what we want, generally.
// 
// useQuery
// useDepotsQuery
// useVehicleChargingQuery
// useDepotChargingQuery
// useLogoQuery

/**
 * Custom hook to execute a GraphQL query with authentication and manage its state.
 * @param {string} query - The GraphQL query to execute.
 * @param {Object} [options={}] - Options for the query execution.
 * @param {Object} [options.variables=null] - Variables for the GraphQL query.
 * @param {number} [options.refetchInterval=null] - Interval in milliseconds to refetch the query.
 * @param {boolean} [options.enable=true] - Whether the query should be enabled.
 * @returns {Object} The state of the query and a function to refetch the data.
 */
function useQuery(query, options = {}) {
  const { variables = null, refetchInterval = null, enable = true } = options;
  const context = useContext(QueryContext);
  if (!context) throw new Error("Missing QueryContext");

  const { fetch } = context;
  const { getAccessTokenSilently, error: auth0_error, user: auth0_user, isLoading } = useAuth0();
  const [state, setState] = useState({
    loading: true,
    querying: false,
    error: null,
    refetching: false,
    refetch: 0,
    data: null,
    enabled: enable,
    queryVars: variables,
  });

  const refetchData = (newVariables = null, newEnable = null) => {
    setState(prevState => ({
      ...prevState,
      queryVars: newVariables || prevState.queryVars,
      enabled: newEnable !== null ? newEnable : prevState.enabled,
      refetch: prevState.refetch + 1,
    }));
  };

  useEffect(() => {
    const fetchData = async (isRefetch) => {

      // console.log("fetchData called, querying", query.replace("\n", " ").substring(0,20), state.queryVars)
      // console.trace();
      if (!state.querying) {
        setState(prevState => ({ ...prevState, querying: true, error: null, loading: true }));
        try {
          if (state.enabled) {
            const accessToken = await getAccessTokenSilently({
              audience: auth_config.audience,
              scope: auth_config.scope,
            });
            const data = await fetch(query, accessToken, state.queryVars);
            setState(prevState => ({ ...prevState, data, loading: false }));
          }
        } catch (error) {
          console.error(error);
          console.log("fetchData called, error", query.replace("\n", " ").substring(0, 20), state.queryVars)
          setState(prevState => ({ ...prevState, error, loading: false }));
        } finally {

          // console.log("fetchData called, finally", query.replace("\n", " ").substring(0,20), state.queryVars)
          setState(prevState => ({ ...prevState, querying: false, refetching: isRefetch ? false : prevState.refetching }));
        }
      } else {
        console.log("fetchData called, already querying", query.replace("\n", " ").substring(0, 20), state.queryVars)
      }
    };

    fetchData(false);

    if (refetchInterval != null) {
      const interval = setInterval(() => {
        console.log("Interval triggered: ", refetchInterval, interval, state, query.replace("\n", " ").substring(0, 20));
        // Clear any old error before querying
        // setError(null);
        setState(prevState => ({ ...prevState, querying: false, error: null }));   // BMH null or false?
        refetchData();
      }, refetchInterval);
      return () => {
        clearInterval(interval);
        // console.log("Interval cleared: ", interval);
      };
    }
    // let intervalId;
    // if (refetchInterval) {
    //   intervalId = setInterval(() => fetchData(true), refetchInterval);
    // }
    // return () => clearInterval(intervalId);
  }, [fetch, query, refetchInterval, state.enabled, state.refetch, state.queryVars, getAccessTokenSilently]);

  return { ...state, refetchData };
}

/**
 * Custom hook to execute a GraphQL query for depots with authentication and manage its state.
 * @param {string} query - The GraphQL query to execute.
 * @param {Object} [options={}] - Options for the query execution.
 * @param {Object} [options.variables=null] - Variables for the GraphQL query.
 * @param {number} [options.refetchInterval=null] - Interval in milliseconds to refetch the query.
 * @param {boolean} [options.enable=true] - Whether the query should be enabled.
 * @returns {Object} The state of the query and a function to refetch the data.
 */
function useDepotsQuery(query, options = {}) {
  const { variables = null, refetchInterval = null, enable = true } = options;
  const context = useContext(QueryContext);
  if (!context) throw new Error("Missing QueryContext");

  const { fetch } = context;
  const { getAccessTokenSilently, error: auth0_error, user: auth0_user, isLoading } = useAuth0();
  const [state, setState] = useState({
    loading: true,
    querying: false,
    error: null,
    refetching: false,
    refetch: 0,
    data: null,
    enabled: enable,
    queryVars: variables,
  });

  const refetchData = (newVariables = null, newEnable = null) => {
    setState(prevState => ({
      ...prevState,
      queryVars: newVariables || prevState.queryVars,
      enabled: newEnable !== null ? newEnable : prevState.enabled,
      refetch: prevState.refetch + 1,
    }));
  };

  useEffect(() => {
    const fetchData = async (isRefetch) => {

      // console.log("fetchData called, querying", query.replace("\n", " ").substring(0,20), state.queryVars)
      // console.trace();
      if (!state.querying) {
        setState(prevState => ({ ...prevState, querying: true, error: null, loading: true }));
        try {
          if (state.enabled) {
            console.log("AuthConfig: ", auth_config);
            const accessToken = await getAccessTokenSilently({
              // authorizationParams: {
              audience: auth_config.audience,
              scope: auth_config.scope,
              // }
            });
            const data = await fetch(query, accessToken, state.queryVars);
            setState(prevState => ({ ...prevState, data, loading: false }));
          }
        } catch (error) {
          console.error(error);
          console.log("fetchData called, error", query.replace("\n", " ").substring(0, 20), state.queryVars)
          setState(prevState => ({ ...prevState, error, loading: false }));
        } finally {

          // console.log("fetchData called, finally", query.replace("\n", " ").substring(0,20), state.queryVars)
          setState(prevState => ({ ...prevState, querying: false, refetching: isRefetch ? false : prevState.refetching }));
        }
      } else {
        console.log("fetchData called, already querying", query.replace("\n", " ").substring(0, 20), state.queryVars)
      }
    };

    fetchData(false);

    if (refetchInterval != null) {
      const interval = setInterval(() => {
        console.log("Interval triggered: ", refetchInterval, interval, state, query.replace("\n", " ").substring(0, 20));
        // Clear any old error before querying
        // setError(null);
        setState(prevState => ({ ...prevState, querying: false, error: null }));   // BMH null or false?
        refetchData();
      }, refetchInterval);
      return () => {
        clearInterval(interval);
        // console.log("Interval cleared: ", interval);
      };
    }
    // let intervalId;
    // if (refetchInterval) {
    //   intervalId = setInterval(() => fetchData(true), refetchInterval);
    // }
    // return () => clearInterval(intervalId);
  }, [fetch, query, refetchInterval, state.enabled, state.refetch, state.queryVars, getAccessTokenSilently]);

  return { ...state, refetchData };
}

/**
 * Custom hook to execute a GraphQL query for vehicle charging with authentication and manage its state.
 * @param {string} query - The GraphQL query to execute.
 * @param {Object} [options={}] - Options for the query execution.
 * @param {Object} [options.variables=null] - Variables for the GraphQL query.
 * @param {number} [options.refetchInterval=null] - Interval in milliseconds to refetch the query.
 * @param {boolean} [options.enable=true] - Whether the query should be enabled.
 * @returns {Object} The state of the query and a function to refetch the data.
 */
function useVehicleChargingQuery(query, options = {}) {
  const { variables = null, refetchInterval = null, enable = true } = options;
  const context = useContext(QueryContext);
  if (!context) throw new Error("Missing QueryContext");

  const { fetch } = context;
  const { getAccessTokenSilently, error: auth0_error, user: auth0_user, isLoading } = useAuth0();
  const [state, setState] = useState({
    loading: true,
    querying: false,
    error: null,
    refetching: false,
    refetch: 0,
    data: null,
    enabled: enable,
    queryVars: variables,
  });

  const refetchData = (newVariables = null, newEnable = null) => {
    setState(prevState => ({
      ...prevState,
      queryVars: newVariables || prevState.queryVars,
      enabled: newEnable !== null ? newEnable : prevState.enabled,
      refetch: prevState.refetch + 1,
    }));
  };

  useEffect(() => {
    const fetchData = async (isRefetch) => {
      console.log("fetchData called, querying", query.replace("\n", " ").substring(0,20), state.queryVars)
      console.trace();
      if (!state.querying) {
        setState(prevState => ({ ...prevState, querying: true, error: null, loading: true }));
        try {
          if (state.enabled) {
            const accessToken = await getAccessTokenSilently({
              audience: auth_config.audience,
              scope: auth_config.scope,
            });
            const data = await fetch(query, accessToken, state.queryVars);
            setState(prevState => ({ ...prevState, data, loading: false }));
          }
        } catch (error) {
          console.error(error);
          console.log("fetchData called, error", query.replace("\n", " ").substring(0, 20), state.queryVars)
          setState(prevState => ({ ...prevState, error, loading: false }));
        } finally {
          console.log("fetchData called, finally", query.replace("\n", " ").substring(0, 20), state.queryVars)
          setState(prevState => ({ ...prevState, querying: false, refetching: isRefetch ? false : prevState.refetching }));
        }
      } else {
        console.log("fetchData called, already querying", query.replace("\n", " ").substring(0, 20), state.queryVars)
      }
    };

    fetchData(false);

    if (refetchInterval != null) {
      const interval = setInterval(() => {
        console.log("Interval triggered: ", refetchInterval, interval, state, query.replace("\n", " ").substring(0, 20));
        // Clear any old error before querying
        // setError(null);
        setState(prevState => ({ ...prevState, querying: false, error: null }));   // BMH null or false?
        refetchData();
      }, refetchInterval);
      return () => {
        clearInterval(interval);
        // console.log("Interval cleared: ", interval);
      };
    }
    // let intervalId;
    // if (refetchInterval) {
    //   intervalId = setInterval(() => fetchData(true), refetchInterval);
    // }
    // return () => clearInterval(intervalId);
  }, [fetch, query, refetchInterval, state.enabled, state.refetch, state.queryVars, getAccessTokenSilently]);

  return { ...state, refetchData };
}

/**
 * Custom hook to execute a GraphQL query for depot charging with authentication and manage its state.
 * @param {string} query - The GraphQL query to execute.
 * @param {Object} [options={}] - Options for the query execution.
 * @param {Object} [options.variables=null] - Variables for the GraphQL query.
 * @param {number} [options.refetchInterval=null] - Interval in milliseconds to refetch the query.
 * @param {boolean} [options.enable=true] - Whether the query should be enabled.
 * @returns {Object} The state of the query and a function to refetch the data.
 */
function useDepotChargingQuery(query, options = {}) {
  const { variables = null, refetchInterval = null, enable = true } = options;
  const context = useContext(QueryContext);
  if (!context) throw new Error("Missing QueryContext");

  const { fetch } = context;
  const { getAccessTokenSilently, error: auth0_error, user: auth0_user, isLoading } = useAuth0();
  const [state, setState] = useState({
    loading: true,
    querying: false,
    error: null,
    refetching: false,
    refetch: 0,
    data: null,
    enabled: enable,
    queryVars: variables,
  });

  const refetchData = (newVariables = null, newEnable = null) => {
    setState(prevState => ({
      ...prevState,
      queryVars: newVariables || prevState.queryVars,
      enabled: newEnable !== null ? newEnable : prevState.enabled,
      refetch: prevState.refetch + 1,
    }));
  };

  useEffect(() => {
    const fetchData = async (isRefetch) => {
      console.log("fetchData Depot called, querying", query.replace("\n", " ").substring(0, 20), state.queryVars)
      // console.trace();
      if (!state.querying) {
        setState(prevState => ({ ...prevState, querying: true, error: null, loading: true }));
        console.log("fetchData querying set to: ", state.querying)
        try {
          if (state.enabled) {
            const accessToken = await getAccessTokenSilently({
              audience: auth_config.audience,
              scope: auth_config.scope,
            });
            const data = await fetch(query, accessToken, state.queryVars);
            setState(prevState => ({ ...prevState, data, loading: false }));
          }
        } catch (error) {
          console.error(error);
          console.log("fetchData called, error", query.replace("\n", " ").substring(0, 20), state.queryVars)
          setState(prevState => ({ ...prevState, error, loading: false }));
        } finally {
          console.log("fetchData called, finally", query.replace("\n", " ").substring(0, 20), state.queryVars)
          setState(prevState => ({ ...prevState, querying: false, refetching: isRefetch ? false : prevState.refetching }));
          console.log("fetchData querying set to: ", state.querying)
        }
      } else {
        console.log("fetchData Depot called, already querying", query.replace("\n", " ").substring(0, 20), state.queryVars)
      }
    };

    fetchData(false);

    if (refetchInterval != null) {
      const interval = setInterval(() => {
        console.log("Interval triggered: ", refetchInterval, interval, state, query.replace("\n", " ").substring(0, 20));
        // Clear any old error before querying
        // setError(null);
        setState(prevState => ({ ...prevState, querying: false, error: null }));   // BMH null or false?
        console.log("fetchData querying set to: ", state.querying)
        // fetchData(true);
        refetchData();
      }, refetchInterval);
      return () => {
        clearInterval(interval);
        console.log("Interval cleared: ", interval);
        setState(prevState => ({ ...prevState, querying: false, error: null }));   // BMH null or false?

        console.log("fetchData querying set to: ", state.querying)
      };
    }
    // let intervalId;
    // if (refetchInterval) {
    //   intervalId = setInterval(() => fetchData(true), refetchInterval);
    // }
    // return () => clearInterval(intervalId);
  }, [fetch, query, refetchInterval, state.enabled, state.refetch, state.queryVars, getAccessTokenSilently]);

  return { ...state, refetchData };
}

/**
 * Custom hook to execute a GraphQL query for logos with authentication and manage its state.
 * @param {string} query - The GraphQL query to execute.
 * @param {Object} [options={}] - Options for the query execution.
 * @param {Object} [options.variables=null] - Variables for the GraphQL query.
 * @param {number} [options.refetchInterval=null] - Interval in milliseconds to refetch the query.
 * @param {boolean} [options.enable=true] - Whether the query should be enabled.
 * @returns {Object} The state of the query and a function to refetch the data.
 */
function useLogoQuery(query, options = {}) {
  const { variables = null, refetchInterval = null, enable = true } = options;
  const context = useContext(QueryContext);
  if (!context) throw new Error("Missing QueryContext");

  const { fetch } = context;
  const { getAccessTokenSilently, error: auth0_error, user: auth0_user, isLoading } = useAuth0();
  const [state, setState] = useState({
    loading: true,
    querying: false,
    error: null,
    refetching: false,
    refetch: 0,
    data: null,
    enabled: enable,
    queryVars: variables,
  });

  const refetchData = (newVariables = null, newEnable = null) => {
    setState(prevState => ({
      ...prevState,
      queryVars: newVariables || prevState.queryVars,
      enabled: newEnable !== null ? newEnable : prevState.enabled,
      refetch: prevState.refetch + 1,
    }));
  };

  useEffect(() => {
    const fetchData = async (isRefetch) => {
      console.log("fetchData Logo called, querying", query.replace("\n", " ").substring(0, 20), state.queryVars)
      // console.trace();
      if (!state.querying) {
        setState(prevState => ({ ...prevState, querying: true, error: null, loading: true }));
        try {
          if (state.enabled) {
            const accessToken = await getAccessTokenSilently({
              audience: auth_config.audience,
              scope: auth_config.scope,
            });
            const data = await fetch(query, accessToken, state.queryVars);
            setState(prevState => ({ ...prevState, data, loading: false }));
          }
        } catch (error) {
          console.error(error);
          console.log("fetchData called, error", query.replace("\n", " ").substring(0, 20), state.queryVars)
          setState(prevState => ({ ...prevState, error, loading: false }));
        } finally {
          console.log("fetchData called, finally", query.replace("\n", " ").substring(0, 20), state.queryVars)
          setState(prevState => ({ ...prevState, querying: false, refetching: isRefetch ? false : prevState.refetching }));
        }
      } else {
        console.log("fetchData Logo called, already querying", query.replace("\n", " ").substring(0, 20), state.queryVars)
      }
    };

    fetchData(false);

    if (refetchInterval != null) {
      const interval = setInterval(() => {
        console.log("Interval triggered: ", refetchInterval, interval, state, query.replace("\n", " ").substring(0, 20));
        // Clear any old error before querying
        // setError(null);
        setState(prevState => ({ ...prevState, querying: false, error: null }));   // BMH null or false?
        refetchData();
      }, refetchInterval);
      return () => {
        clearInterval(interval);
        // console.log("Interval cleared: ", interval);
      };
    }
    // let intervalId;
    // if (refetchInterval) {
    //   intervalId = setInterval(() => fetchData(true), refetchInterval);
    // }
    // return () => clearInterval(intervalId);
  }, [fetch, query, refetchInterval, state.enabled, state.refetch, state.queryVars, getAccessTokenSilently]);

  return { ...state, refetchData };
}

export { QueryContextProvider, useQuery, useDepotChargingQuery, useLogoQuery, QueryContext, useVehicleChargingQuery, useDepotsQuery };