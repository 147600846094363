import { Box, Card, CardHeader } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from "react";
import { useParams } from 'react-router-dom';

import { Loading } from "components/common";
import GemDrawerMenu from "components/common/DrawerMenu";

import { useQuery, useUser } from "contexts";
import { charge_logs_query } from "queries";


function formatDate(date) {
  if (!date) {
    return 'None';
  }
  // return date.toLocaleString();
  return date.toLocaleString('en-GB', { hour12: false });
}


function ChargeLogPage() {
  let { id } = useParams()

  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
   useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: depot_data, error: depot_error } = 
    useQuery(charge_logs_query, { variables: { depot: id }, refetchInterval: 10000});

  console.log("query result: ", depot_data);
  
  let depot = null;
  if (depot_data) {
    depot = depot_data.depot;
  }
  return (
    <Box display="flex" width="100%">
      <GemDrawerMenu />
      <Box sx={{ flexGrow: 1, padding: '5px' }}>
        <Card>
            <CardHeader title="Charge Log" />
            <Box sx={{ height: '100%', width: '100%' }}>
            { depot ? (
            <DataGrid
                columnHeaderHeight={34}
                rowHeight={34}
                columns={[
                // { field: 'id', headerName: 'ID', width: 50, type: 'number' },
                { field: 'vehicle', headerName: 'Vehicle', width: 100, type: 'number' },
                { field: 'chargeStart', headerName: 'Start', width: 200, type: 'dateTime',
                  renderCell: (params) => (
                  <div>
                    {formatDate(params.value)}
                  </div>
                  )
                },
                { field: 'chargeEnd', headerName: 'End', width: 200, type: 'dateTime',
                  renderCell: (params) => (
                  <div>
                    {formatDate(params.value)}
                  </div>
                  )
                },
                { field: 'startSoc', headerName: 'Start SOC', width: 100, type: 'number' },
                { field: 'endSoc', headerName: 'End SOC', width: 100, type: 'number' },
                // { field: 'chargeCompleted', headerName: 'Completed', width: 100, type: 'boolean' },
                // { field: 'meterStart', headerName: 'Meter Start', width: 100, type: 'number' },
                // { field: 'meterEnd', headerName: 'Meter End', width: 100, type: 'number' },
                { field: 'kwhDelivered', headerName: 'kWh', width: 50, type: 'number' },
                { field: 'chargePointConnector', headerName: 'Connector', width: 150, type: 'string' },
                { field: 'transactionId', headerName: 'Transaction ID', width: 180, type: 'string' },
                { field: 'chargeType', headerName: 'Type', width: 200, type: 'string' },

                ]}
                rows={depot.chargeLogs.map(cl => ({
                  id: cl.id,
                  chargeStart: new Date(cl.chargeStart),
                  chargeEnd: new Date(cl.chargeEnd),
                  startSoc: cl.startSoc,
                  endSoc: cl.endSoc,
                  chargeCompleted: cl.chargeCompleted,
                  createdAt: new Date(cl.createdAt),
                  updatedAt: new Date(cl.updatedAt),
                  vehicle: cl.vehicle.identifier,
                  meterStart: cl.meterStart,
                  meterEnd: cl.meterEnd,
                  kwhDelivered: cl.kwhDelivered,
                  chargePointConnector: cl.chargePointConnector.chargePoint.name+":"+cl.chargePointConnector.connectorId,
                  transactionId: cl.transactionId,
                  chargeType: cl.chargeType,
                }))}
                pageSize={10}
                sortModel={[
                  { field: 'vehicle', sort: 'asc' },
                  // { field: 'chargeStart', sort: 'desc' },
                ]}
                // getRowClassName={(params) => `vehicle-${params.row.vehicle}`}
                getRowClassName={(params) => `vehicle-${params.row.vehicle % 40}`}
                />
            ) : (
              <Loading />
            )}
          </Box>
        </Card>
        </Box>
      </Box>
      );
    }

  export { ChargeLogPage };


