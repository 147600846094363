// React Dashboard from here: https://mui.com/material-ui/getting-started/templates/
// Looks like a good design. I want that.


import { Chip } from '@mui/material';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { GraphQLClient } from 'graphql-request';
import { get_vehicle_possible_schedules, link_schedule_vehicle_gql, vehicle_charging_query_schedules } from "queries";
import React from 'react';
import auth_config from "../auth_config.json";

import {
  DataGrid,
  GridToolbarContainer,
  useGridApiContext
} from '@mui/x-data-grid';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from '@mui/material/styles';

import { useEffect, useState } from 'react';

import { Box, Button, FormGroup, FormLabel, Input, Paper, Stack, Switch, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { ArcElement, Chart as ChartJS, Filler, LineElement, PointElement } from 'chart.js';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoom from 'chartjs-plugin-zoom';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { DualSlider, Loading } from 'common';
import GemDrawerMenu from "components/common/DrawerMenu";

import { useConnection, useQuery, useUser, useVehicleChargingQuery } from "contexts";
import { reschedule_charging_mutation, start_charging_mutation, stop_charging_mutation, vehicle_charging_query } from "queries";
import { toast } from 'react-toastify';
import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';
import { formatDistanceToNow } from 'date-fns';
import { Icon } from '@mui/material';
import { Tooltip } from '@mui/material';

function VehicleChargingView() {
  const { getAccessTokenSilently, error: auth0_error, user: auth0_user, isLoading } = useAuth0();

  const ac = new AbortController();
  const endpoint = (process.env.REACT_APP_GEMINI_URL || "https://gemini.orionair.com.au/graphql").trim();

  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
  useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { dispatch: conn_dispatch } = useConnection();
  const [vehicle, set_vehicle] = useState(null)
  const [manually_charging, set_manually_charging] = useState(false)

  // Gets the vehicle id from the url
  let { vehicle_id } = useParams();

  const {
    data: start_charging_data,
    loading: start_charging_loading,
    error: start_charging_error,
    refetchData: fetch_start_charging
  } = useQuery(start_charging_mutation, { enable: false });

  const {
    data: stop_charging_data,
    loading: stop_charging_loading,
    error: stop_charging_error,
    refetchData: fetch_stop_charging
  } = useQuery(stop_charging_mutation, { enable: false });

  const {
    data: reschedule_charging_data,
    loading: reschedule_charging_loading,
    error: reschedule_charging_error,
    refetchData: fetch_reschedule_charging
  } = useQuery(reschedule_charging_mutation, { enable: false });

  const {
    data: vehicle_charging_data,
    loading: vehicle_charging_loading,
    error: vehicle_charging_error,
  } = useVehicleChargingQuery(vehicle_charging_query, { variables: { vehicle: vehicle_id }, refetchInterval: 10000 });

  // Have used the same function as above, but with a different query. Hopefully that's OK.
  const {
    data: vehicle_charging_data_schedules,
    loading: vehicle_charging_loading_schedules,
    error: vehicle_charging_error_schedules,
  } = useVehicleChargingQuery(vehicle_charging_query_schedules, { variables: { vehicle: vehicle_id } }); // no refetch

  const {
    data: vehiclePossibleSchedules,
    loading: vehicle_possible_schedules_loading,
    error: vehicle_possible_schedules_error,
  } = useQuery(get_vehicle_possible_schedules, { variables: { vehicleId: vehicle_id } });     // No Refetch, interferes with saving. (Well, it didn't help actually)

  async function on_start_charging() {
    fetch_start_charging({ vehicle_id }, true);
    set_manually_charging(true);
  }

  async function on_stop_charging() {
    fetch_stop_charging({ vehicle_id }, true);
    set_manually_charging(false);
  }

  async function on_reschedule_charging() {
    fetch_reschedule_charging({ vehicle_id }, true);
    set_manually_charging(false);
  }

  useEffect(() => {
    if (start_charging_error !== null) {
      console.error(start_charging_error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!start_charging_loading) {
      fetch_start_charging(null, false);
      const { startCharging } = start_charging_data;
      console.log(startCharging.responseCode)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, fetch_start_charging, start_charging_data, start_charging_loading, start_charging_error]);

  useEffect(() => {
    if (stop_charging_error !== null) {
      console.error(stop_charging_error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!stop_charging_loading) {
      fetch_stop_charging(null, false);
      const { stopCharging } = stop_charging_data;
      console.log(stopCharging.responseCode)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, fetch_stop_charging, stop_charging_data, stop_charging_loading, stop_charging_error]);

  useEffect(() => {
    if (reschedule_charging_error !== null) {
      console.error(reschedule_charging_error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!reschedule_charging_loading) {
      fetch_reschedule_charging(null, false);
      const { rescheduleCharging } = reschedule_charging_data;
      console.log(rescheduleCharging.responseCode)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, fetch_reschedule_charging, reschedule_charging_data, reschedule_charging_loading, reschedule_charging_error]);

  const [selectedRows, setRowSelectionModel] = React.useState([]);  // Just an array of row ids
  // const [depotSchedules, setDepotSchedules] = React.useState([]);

  // Vehicle data load
  useEffect(() => {
    if (vehicle_charging_error !== null) {
      console.error(vehicle_charging_error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!vehicle_charging_loading) {
      const { vehicle } = vehicle_charging_data;
      // setRowSelectionModel(vehicle.chargingSchedules.map((x) => x.id));
      set_vehicle(vehicle);
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, vehicle_charging_data, vehicle_charging_loading, vehicle_charging_error]);

  useEffect(() => {
    if (!vehicle_charging_loading_schedules) {
      console.log("Loading vehicle_charging_data_schedules", vehicle_charging_data_schedules, vehicle_charging_error_schedules);
      if (vehicle_charging_data_schedules.vehicle){
        setRowSelectionModel(vehicle_charging_data_schedules.vehicle.chargingSchedules.map((x) => x.id));
      }
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, vehicle_charging_data_schedules, vehicle_charging_loading_schedules]);


  if (vehicle == null || vehicle_charging_data_schedules == null) return <Loading />

  // const { data: depotSchedules, loading: loadingOrgSchedules, error: errorOrgSchedules } = useQuery(get_depot_schedules, { variables: { depotId: vehicle.depot_id } });
  // console.log("depotSchedules", depotSchedules);

  // TODO: Show vehicle charging schedule history
  // TODO: Show vehicle current state
  // {charging_vehicle.vehicle.lastBatteryCycle.lowestStateOfCharge}
  // {charging_vehicle.vehicle.lastBatteryCycle.highestStateOfCharge}
  // {charging_vehicle.vehicle.lastBatteryCycle.startedStateOfCharge}
  // {charging_vehicle.vehicle.lastBatteryCycle.finishedStateOfCharge}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeCurrentLimit}
  // {charging_vehicle.vehicle.currentChargingSchedule.scheduledChargingEndSoc}
  // {charging_vehicle.vehicle.currentChargingSchedule.scheduledChargingStartSoc}
  // {charging_vehicle.vehicle.currentChargingSchedule.scheduledChargingEndTime}
  // {charging_vehicle.vehicle.currentChargingSchedule.scheduledChargingStartTime}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.endSoc}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.startSoc}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.chargeEnd}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.chargeStart}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.chargeCompleted}

  // console.log("vehicle.chargePointConnector", vehicle.chargePointConnector);
  let minutes = 3;
  const start_charging_button = (
    <Button
      onClick={on_start_charging}
      variant="contained"
      sx={{ width: "100%" }}
      // disabled={!["connected", "manual_connected"].includes(vehicle.chargePointConnector?.state) || manually_charging || vehicle.chargePointConnector?.chargePoint.ocppState?.last_meter_values === null || (Date.now() - new Date(vehicle.chargePointConnector?.chargePoint.ocppState.last_meter_values).getTime()) > minutes * 60 * 1000}
      disabled={!["connected", "manual_connected"].includes(vehicle.chargePointConnector?.state) || manually_charging}
    >
      {manually_charging ? "Manual Charging" : "Start Charging"}
    </Button>
  );
  const stop_charging_button = (
    <Button
      onClick={on_stop_charging}
      variant="contained"
      sx={{ width: "100%" }}
      disabled={vehicle.chargePointConnector?.state !== "charging" && vehicle.chargePointConnector?.state !== "manual_charging"}
    >
      Stop Charging
    </Button>
  );

  // var reschedule_charging_button;
  // console.log("manually_charging", manually_charging, vehicle);

  const reschedule_charging_button = (
    <Button
      onClick={on_reschedule_charging}
      variant="contained"
      sx={{ width: "100%" }}
      disabled={vehicle.chargePointConnector?.state !== "manual_charging" && vehicle.chargePointConnector?.state !== "manual_connected"}
    >
      Reschedule Charging
    </Button>
  );

  const charge_point_connector = vehicle == null ? null : vehicle.chargePointConnector;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

  const getTime = (value, row) => {
    // console.log("getStartTime", value);
    if (value === undefined || value === null) {
      return value;
    }
    const d = new Date(value);
    return d.getHours().toString().padStart(2, 0) + ":" + d.getMinutes().toString().padStart(2, 0);
    // return new Date(value).getTime();
  };

  const renderDayCell = (params) => {
    // const isDisabled = params.row.dateFrom !== undefined;
    if (params.value) {
      return <CheckBoxIcon />;
    } else {
      return null;
    }
  };
  const getDate = (value, row) => {
    // console.log("getStartDate", value);
    if (value === undefined || value === null) {
      return value;
    }
    return new Date(value);
  };
  function getColumns(rowModesModel, handleSaveClick, handleCancelClick, handleEditClick) {
    const dayWidth = 30;
    return [

      { field: 'name', headerName: 'Name', width: 350, editable: true },
      { field: 'chargeCurrentLimit', headerName: 'kW', type: 'number', width: 70, editable: false },
      {
        field: 'startDate', headerName: 'Date From', type: 'date', width: 120, editable: false,
        valueGetter: getDate,
      },
      {
        field: 'endDate', headerName: 'Date To', type: 'date', width: 120, editable: false,
        valueGetter: getDate
      },
      { field: 'monday', headerName: 'M', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: false, renderCell: renderDayCell },
      { field: 'tuesday', headerName: 'T', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: false, renderCell: renderDayCell },
      { field: 'wednesday', headerName: 'W', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: false, renderCell: renderDayCell },
      { field: 'thursday', headerName: 'T', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: false, renderCell: renderDayCell },
      { field: 'friday', headerName: 'F', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: false, renderCell: renderDayCell },
      { field: 'saturday', headerName: 'S', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: false, renderCell: renderDayCell },
      { field: 'sunday', headerName: 'S', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: false, renderCell: renderDayCell },

      // { field: 'canStartTime', headerName: 'Can Start', width: 100, editable: true },
      {
        field: 'scheduledChargingStartTime', headerName: 'Start Time', width: 100, editable: false,
      },
      {
        field: 'scheduledChargingEndTime', headerName: 'End Time', width: 100, editable: false,
      },
    ];
  }

  async function link_schedule_vehicle(vehicleId, scheduleIds) {
    const toastId = toast.loading("Linking schedules to vehicle ...");
    try {
      const accessToken = await getAccessTokenSilently({
        audience: auth_config.audience,
        scope: auth_config.scope,
      });

      console.log("About to call graphql", vehicleId, scheduleIds);
      const client = new GraphQLClient(endpoint, { headers: { "Authorization": `Bearer ${accessToken}` }, signal: ac.signal });
      const data = await client.request(link_schedule_vehicle_gql, { vehicle: vehicleId, schedules: scheduleIds });
      console.log(data);
      toast.update(toastId, { render: "Schedules linked to vehicle", type: "success", isLoading: false, autoClose: 5000 });
      return scheduleIds;
    } catch (error) {
      console.log("Error linking schedules to vehicle", error);
      toast.update(toastId, { render: "Error linking schedules to vehicle: " + error.response.errors[0].message, type: "error", isLoading: false, autoClose: 10000 });
      console.error(error);
    }
  }


  function handleSaveClick(apiRef) {
    console.log("Save clicked");

    if (window.confirm("Are you sure you want to save?")) {
      const rows = apiRef.current.getSelectedRows();
      console.log("Selected rows", rows);
      link_schedule_vehicle(vehicle.id, selectedRows);
    }
  }

  function CustomToolbar(props) {
    const {vehicle_charging_data} = props;
    const currentUser = vehicle_charging_data.currentUser;

    const apiRef = useGridApiContext();

    return (
      <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      {vehicle_charging_data.currentUser.isAdmin && <Button variant="contained" onClick={() => handleSaveClick(apiRef)}>Save</Button>}
      </GridToolbarContainer>
    );
  }

  const StyleChip = styled(Chip)(({ color }) => ({
    backgroundColor: color
  }));

  const fieldandvalue = (field, value, chip = false, color = null) => {
    // console.log("fieldandvalue", field, value, chip, color);
    return (
      <Grid container spacing={2} >
        <Grid width="200px" sx={{ textAlign: 'right', paddingRight: '2px' }}>
          {field}:
        </Grid>
        <Grid sx={{ paddingLeft: '0px', paddingRight: '10px' }}>
          {chip ? <StyleChip label={value} color={color} size='small' variant='outlined'/> : value}
        </Grid>
      </Grid>
    )
  }



  if (vehiclePossibleSchedules == null) return <Loading />

  const schedulesDataGrid = <DataGrid
    initialState={{
      pagination: {paginationModel: {pageSize: 10}},
    }}
    checkboxSelection
    density='compact' 
    slots={{
      toolbar: CustomToolbar,
    }}
    slotProps={{
      toolbar: { vehicle_charging_data },
    }}
    rows={vehiclePossibleSchedules.vehiclePossibleChargingSchedules}
    columns={getColumns()}

    onRowSelectionModelChange={(newRowSelectionModel) => {
      setRowSelectionModel(newRowSelectionModel);
    }}
    rowSelectionModel={selectedRows}
    isRowSelectable={(params) => vehicle_charging_data.currentUser.isAdmin}
    getRowClassName={(params) => {
      if (!selectedRows.includes(params.id)) {
        return 'greyed';
      }
      return '';
    }}
    sx={{
      ".greyed": {
        // bgcolor: "grey",
        color: "lightgrey", // Add this line to make text light grey
        // "&:hover": {
        //   bgcolor: "darkgrey",
        // },
      },
    }}  />

  function getDepotTextAndColour(depotState) {
    let depot_text = "";
    let depot_box_color = "";
    if (depotState === "within_depot") {
      depot_text = "In Depot";
      depot_box_color = "#0055b3";
    } else if (depotState === "outside_depot") {
      depot_text = "Running";
      depot_box_color = "#028A0F";
    }
    return { depot_text, depot_box_color };
  }

  function getStateTextAndColour(state) {
    let state_text = "";
    let state_box_color = "";
    if (state === "charging") {
      state_text = "Charging";
      state_box_color = "#028A0F";
    } else if (state === "connected") {
      state_text = "Connected";
      state_box_color = "#0055b3";
    } else if (state === "manual_charging") {
      state_text = "Manual Charging";
      state_box_color = "#B53737";
    } else if (state === "manual_connected") {
      state_text = "Manual Connected";
      state_box_color = "#B53737";
    } else if (state === "disconnected") {
      state_text = "Disconnected";
      state_box_color = "#878787";
    } else if (state === "unidentified") {
      state_text = "Identifying";
      state_box_color = "#B53737";
    } else if (state === "hard_faulted") {
      state_text = "Charge Point Faulted";
      state_box_color = "#B53737";
    }
    return { state_text, state_box_color };
  }

  function getStateIcon(cpc) {
    console.log("cpc", cpc);
    if (!cpc) {
      return null;
    }
    let icon;
    let oldText = "";
    let color = "green";
    let minutes = 3;

    if (cpc.ocppState?.last_meter_values && 
      (Date.now() - new Date(cpc.chargePoint.ocppState.last_meter_values).getTime()) > minutes * 60 * 1000) {
      oldText = ": " + formatDistanceToNow(new Date(cpc.chargePoint.ocppState.last_meter_values), { addSuffix: true });
      color = "orange";
  }

  switch (cpc.state) {
    case "connected":
      icon = (
        <Tooltip title={`Connected${oldText}`}>
        <PowerIcon style={{ color: color }} />
        </Tooltip>
      );
      break;
    case "charging":
    case "manual_charging":
      icon = (
        <Tooltip title={`${cpc.state === "charging" ? "Charging" : "Manually Charging"}${oldText}`}>
        <BoltIcon className="animate-bolt" style={{ color: color }} />
        {cpc.state === "manual_charging" && "M"}
        </Tooltip>
      );
      break;
    case "manual_connected":
      icon = (
        <Tooltip title={`Manually Connected${oldText}`} >
        <PowerIcon style={{ color: color }}/>M
        </Tooltip>
      );
      break;
    default:
      icon = <Icon style={{ color: color }}/>;
      break;
  }
  // console.log("returning icon", icon);
  return icon;
      }


  let { state_text, state_box_color } = getStateTextAndColour(vehicle.chargePointConnector?.state);
  let { depot_text, depot_box_color } = getDepotTextAndColour(vehicle.depotState);
  let state_icon = getStateIcon(vehicle.chargePointConnector);
  
  console.log("vehicle", vehicle, state_icon);

  const firstRow =
    <Grid container spacing={1} width="100%" sx={{ border: '0px solid black', padding: 0 }}>
      <Grid item size="grow" display="flex" sx={{ border: '0px solid black' }}>
        <Card elevation={3} sx={{ width: '100%' }}>
          <Box sx={{ backgroundColor: '#07A0C3', width: '100%', padding: '0px' }}>
            <CardHeader title="Vehicle Information" />
          </Box>
          <CardContent>
            {fieldandvalue("Identifier", vehicle.displayName)}
            {fieldandvalue("Location", depot_text, true, depot_box_color)}
            {fieldandvalue("OCPP ID", vehicle.ocppId)}
            {/* {fieldandvalue("Model", vehicle.model)} */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item size="grow" display="flex" sx={{ border: '0px solid black' }}>
        <Card elevation={3} sx={{ width: '100%' }}>
          <Box sx={{ backgroundColor: '#07A0C3', width: '100%', padding: '0px' }}>
            <CardHeader title="Charging Information" />
          </Box>
          <CardContent>
            {fieldandvalue("Battery Level", vehicle.stateOfCharge)}
            {fieldandvalue("State", state_icon)}
            {fieldandvalue("Battery Cycles", vehicle.totalBatteryCycles)}
            {fieldandvalue("Capacity", vehicle.energyStoreSize + " " + vehicle.energyStoreUnits)}
            {fieldandvalue("OCPP State", vehicle.chargePointConnector?.ocppState?.state)}
          </CardContent>
        </Card>
      </Grid>
      <Grid item size="grow" display="flex" sx={{ border: '0px solid black' }}>
        <Card elevation={3} sx={{ width: '100%' }}>
          <Box sx={{ backgroundColor: '#07A0C3', width: '100%', padding: '0px' }}>
            <CardHeader title="Actions" />
          </Box>
          <CardContent>
            {start_charging_button}
            <Box sx={{ height: '5px' }} />
            {stop_charging_button}
            <Box sx={{ height: '5px' }} />
            {reschedule_charging_button}
          </CardContent>
        </Card>
      </Grid>
    </Grid>

  return (
    <Box display="flex" width="100%" height="100vh" padding={0.5}>
      <GemDrawerMenu />
      <Grid rowSpacing={1} container width="100%" spacing={2} sx={{ border: '0px solid blue' }}>
        <Box sx={{ flexGrow: 1, p: 2, border: '0px solid blue' }}>
          <Grid item size={12} sx={{ border: '0px solid pink' }}>
            {firstRow}
          </Grid>
        </Box>
        <Grid item size={12} sx={{ height: "66%", border: '0px solid green' }}>
          <Card elevation={5} sx={{ width: "100%", height: "100%" }} >
            <Box sx={{ backgroundColor: '#07A0C3', width: '100%', padding: '0px' }}>

              <CardHeader title="Assign Schedules" />
            </Box>
            <CardContent>
              {schedulesDataGrid}
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item size={12} sx={{ height: "200%", border: '0px solid red' }}>
          <Card elevation={5} sx={{ width: "100%", height: "100%" }} >
            <Box sx={{ backgroundColor: '#07A0C3', width: '100%', padding: '0px' }}>
              <CardHeader title="Battery Cycles" />
            </Box>
            <CardContent>
              <Box sx={{ height: '100%', width: '100%' }}>
                <DataGrid
                  density='compact'
                  rows={vehicle.batteryCycles.map(cycle => ({
                    ...cycle,
                    startedAt: new Date(cycle.startedAt),
                    finishedAt: new Date(cycle.finishedAt),
                  }))}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'finishedAt', sort: 'desc' }],
                    },
                  }}
                
                  columns={[
                    { field: 'id', headerName: 'ID', width: 30 },
                    { field: 'startedAt', headerName: 'Start', width: 220, type: 'dateTime' },
                    { field: 'finishedAt', headerName: 'End', width: 220, type: 'dateTime' },
                    { field: 'lowestStateOfCharge', headerName: 'Lowest', width: 100 },
                    { field: 'highestStateOfCharge', headerName: 'Highest', width: 100 },
                    { field: 'startedStateOfCharge', headerName: 'Started', width: 100 },
                    { field: 'finishedStateOfCharge', headerName: 'Finished', width: 100 },
                  ]}
                />
              </Box>
            </CardContent>
            
          </Card>
        </Grid> */}
        <Grid item size={12} sx={{ height: "200%", border: '0px solid red' }}>
          <Card elevation={5} sx={{ width: "100%", height: "100%" }} >
            <Box sx={{ backgroundColor: '#07A0C3', width: '100%', padding: '0px' }}>
              <CardHeader title="Charge Log" />
            </Box>
            <CardContent>
              <Box sx={{ height: '100%', width: '100%' }}>
                <DataGrid
                  density='compact'
                  rows={vehicle.chargeLogs.map(cycle => ({
                      ...cycle,
                      chargeStart: new Date(cycle.chargeStart),
                      chargeEnd: cycle.chargeEnd ? new Date(cycle.chargeEnd) : null,
                      chargePointConnector: cycle.chargePointConnector.chargePoint.name+":"+cycle.chargePointConnector.connectorId,
                      transactionId: cycle.transactionId,
                      chargeType: cycle.chargeType,
                    }))}
                    initialState={{
                    sorting: {
                      sortModel: [{ field: 'finishedAt', sort: 'desc' }],
                    },
                    }}
                  
                    columns={[
                      { field: 'id', headerName: 'ID', width: 70 },
                      { field: 'chargeStart', headerName: 'Start', width: 220, type: 'dateTime' },
                      { field: 'chargeEnd', headerName: 'End', width: 220, type: 'dateTime' },
                      { field: 'startSoc', headerName: 'Start SOC', width: 100, type: 'number' },
                      { field: 'endSoc', headerName: 'End SOC', width: 100, type: 'number' },
                      // { field: 'meterStart', headerName: 'Start', width: 100 },
                      // { field: 'meterEnd', headerName: 'End', width: 100 },
                      { field: 'kwhDelivered', headerName: 'kWh', width: 100, type: 'number' },
                      { field: 'chargePointConnector', headerName: 'Connector', width: 150, type: 'string' },
                      { field: 'transactionId', headerName: 'Transaction ID', width: 180, type: 'string' },
                      { field: 'chargeType', headerName: 'Type', width: 200, type: 'string' },
                    ]}
                  />
                  </Box>
                </CardContent>
                
                </Card>
              </Grid>

              </Grid> {/* container*/}
    </Box>
  )

}

export default VehicleChargingView;
