import { Box, Card, CardHeader } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from "react";
import { useParams } from 'react-router-dom';

import { Loading } from "components/common";
import GemDrawerMenu from "components/common/DrawerMenu";

import { useDepotChargingQuery, useUser } from "contexts";
import { formatDistanceToNow } from "date-fns";
import { charge_points_query } from "queries";

function formatDistanceDay(date) {
  // const oneDay = 1000 * 3600 * 24;
  // const distance = Date.now() - date.getTime();
  // console.log("formatDistanceDay", date);
  if (!date) {
    return 'None';
  }
  return formatDistanceToNow(date, { addSuffix: false })
    .replace('about ', '')
    .replace('less than a', '1')
    .replace('minutes', 'mins');
}

function ChargePointsPage() {
  let { id } = useParams()
  console.log("Charging Points Page", id);

  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
   useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: depot_data, error: depot_error } = 
    useDepotChargingQuery(charge_points_query, { variables: { depot: id }, refetchInterval: 10000});


  if (depot_error != null ) {
    return (
      <Box>
        <Box>{depot_error}</Box>
      </Box>
    )
  };

  let depot = null;
  if (depot_data) {
    depot = depot_data.depot;
  }

  // console.log("ChargePointsPage: ", depot.chargePoints);
  return (
    <Box display="flex" width="100%">
      <GemDrawerMenu />
      <Box sx={{ flexGrow: 1, padding: '5px' }}>
        <Card>
            <CardHeader title="Charge Points" />
            <Box sx={{ height: '100%', width: '100%' }}>
            { depot ? (
              <DataGrid
                columnVisibilityModel={{
                  id: false,
                }}
                columnHeaderHeight={34}
                rowHeight={34}
                columnGroupingModel={[
                {
                  groupId: 'Charge Point',
                  children: [{ field: 'id' }, { field: 'name' }, { field: 'identifier' }, { field: 'ocppAllow' }, { field: 'ocppControl' }, { field: 'lastHeartbeat' }, { field: 'lastMeterValues' }],
                },
                {
                  groupId: 'Connector 1',
                  children: [{ field: 'connector1' }, { field: 'vehicle1' }, { field: 'lastStatusNotification1' }, { field: 'lastMeterValues1' }],
                },
                {
                  groupId: 'Connector 2',
                  children: [{ field: 'connector2' }, { field: 'vehicle2' }, { field: 'lastStatusNotification2' }, { field: 'lastMeterValues2' }],
                },
                ]}
                columns={[
                  { field: 'id', headerName: 'ID', width: 50, type: 'number' },
                  { field: 'name', headerName: 'Name', width: 120 },
                  { field: 'identifier', headerName: 'Identifier', width: 200 },
                  { field: 'ocppAllow', headerName: 'Allow', width: 60, type: 'boolean' },
                  { field: 'ocppControl', headerName: 'Control', width: 80, type: 'boolean' },
                  { field: 'lastHeartbeat', headerName: 'Heartbeat', width: 100 },
                  { field: 'lastMeterValues', headerName: 'Meter', width: 100 },
                  // Connector 1
                  { field: 'connector1', headerName: 'Connected', width: 130 },
                  { field: 'vehicle1', headerName: 'Vehicle', width: 100 },
                  { field: 'lastStatusNotification1', headerName: 'Status', width: 100 },
                  { field: 'lastMeterValues1', headerName: 'Meter', width: 100 },
                  // Connector 2
                  { field: 'connector2', headerName: 'Connected', width: 130 },
                  { field: 'vehicle2', headerName: 'Vehicle', width: 100 },
                  { field: 'lastStatusNotification2', headerName: 'Status', width: 100 },
                  { field: 'lastMeterValues2', headerName: 'Meter', width: 100 },
                ]}
                rows={depot.chargePoints.map(cp => ({
                  id: cp.id,
                  name: cp.name,
                  identifier: cp.identifier,
                  ocppAllow: cp.ocppAllow,
                  ocppControl: cp.ocppControl,
                  // lastStatusNotification: formatDistanceDay(cp.ocppState["last_status_notification"]),
                  // lastMeterValues: formatDistanceDay(cp.ocppState["last_meter_values"]),
                  lastHeartbeat: cp.ocppState ? formatDistanceDay(cp.ocppState["last_heartbeat"]) : 'None',
                  lastMeterValues: cp.ocppState ? formatDistanceDay(cp.ocppState["last_meter_values"]) : 'None',

                  connector1: cp.chargePointConnectors[0] ? cp.chargePointConnectors[0].state : 'None',
                  vehicle1: cp.chargePointConnectors[0] && cp.chargePointConnectors[0].vehicle ? cp.chargePointConnectors[0].vehicle.displayName : '-',
                  lastStatusNotification1: cp.chargePointConnectors[0] && cp.chargePointConnectors[0].ocppState ? formatDistanceDay(cp.chargePointConnectors[0].ocppState["last_status_notification"]) : 'None',
                  lastMeterValues1: cp.chargePointConnectors[0] && cp.chargePointConnectors[0].ocppState ? formatDistanceDay(cp.chargePointConnectors[0].ocppState["last_meter_values"]) : 'None',
                  
                  connector2: cp.chargePointConnectors[1] ? cp.chargePointConnectors[1].state : 'None',
                  vehicle2: cp.chargePointConnectors[1] && cp.chargePointConnectors[1].vehicle ? cp.chargePointConnectors[1].vehicle.displayName : '-',
                  lastStatusNotification2: cp.chargePointConnectors[1] && cp.chargePointConnectors[0].ocppState ? formatDistanceDay(cp.chargePointConnectors[1].ocppState["last_status_notification"]) : 'None',
                  lastMeterValues2: cp.chargePointConnectors[1] && cp.chargePointConnectors[0].ocppState ? formatDistanceDay(cp.chargePointConnectors[1].ocppState["last_meter_values"]) : 'None',

                }))}
                pageSize={5}
                rowsPerPageOptions={[5]}
                sortModel={[
                  {
                    field: 'name',
                    sort: 'asc',
                    
                  },
                ]}
              />
            ) : (
              <Loading />
            )}
            </Box>
        </Card>
      </Box>
    </Box>
  );
}

export { ChargePointsPage };

