const charge_points_query = `
  query DepotCharging (
    $depot: ID!,
  ) {
    depot(id: $depot) {
      id
      name
      geofence
      totalPower
      vehicles {
        ocppControl
        id
        displayName
        isElectric
        depotState
        stateOfCharge
        chargingSchedules {
          id
          name
        }
        lastBatteryCycle {
          startedAt
          finishedAt
          lowestStateOfCharge
          highestStateOfCharge
          startedStateOfCharge
          finishedStateOfCharge
        }
        chargePointConnector {
          state
        }
      }
      chargePoints {
        id
        name
        identifier
        ocppAllow
        ocppControl
        ocppState
        chargePointConnectors {
          vehicle { id displayName }
          state
          connectorId
          ocppState
          connectedVehicle
        }
      }
    }
  }
`;


const depot_charging_query = `
  query DepotCharging (
    $depot: ID!,
  ) {
    depot(id: $depot) {
      id
      name
      geofence
      totalPower
      vehicles {
        ocppControl
        id
        displayName
        isElectric
        depotState
        lastPositionAt
        stateOfCharge
        chargingSchedules {
          id
          name
        }
        lastBatteryCycle {
          startedAt
          finishedAt
          lowestStateOfCharge
          highestStateOfCharge
          startedStateOfCharge
          finishedStateOfCharge
        }
        chargePointConnector {
          state
        }
      }
      chargePoints {
        id
        name
        ocppControl
        identifier
        ocppAllow
        ocppControl
        ocppState
        chargePointConnectors {
          state
          connectorId
          ocppState
          connectedVehicle
        }
      }
    }
  }
`;

const depot_plot_power_query = `
query DepotPlotPower($depot: ID!, $from: ISO8601DateTime!, $to:ISO8601DateTime!) {
  plotTotalPower(id: $depot, from: $from, to: $to) {
    value
    observedAt
  }
}
`;

const start_charging_mutation = `
mutation StartCharging($vehicle_id: ID!) {
  startCharging(vehicleId: $vehicle_id) {
    responseCode
  }
}
`;

const stop_charging_mutation = `
mutation StopCharging($vehicle_id: ID!) {
  stopCharging(vehicleId: $vehicle_id) {
    responseCode
  }
}
`;

const reschedule_charging_mutation = `
mutation RescheduleCharging($vehicle_id: ID!) {
  rescheduleCharging(vehicleId: $vehicle_id) {
    responseCode
  }
}
`;

const create_charging_schedule_mutation = `
      mutation CreateChargingSchedule($input: ChargingScheduleInput!) {
        createChargingSchedule(
          input: $input
        ) {
          id
        }
      }
`;

export { depot_charging_query, depot_plot_power_query, start_charging_mutation, stop_charging_mutation, reschedule_charging_mutation, create_charging_schedule_mutation, charge_points_query };
