import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';
import { Chip, Icon, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import {
  DataGrid
} from '@mui/x-data-grid';
import clsx from 'clsx';

import { formatDistanceToNow } from 'date-fns';


const ac = new AbortController();

function ChargingVehicleList({ vehicles, charge_points }) {
  const navigate = useNavigate();

  vehicles?.map((v) => {
    charge_points.forEach(cp => {
      cp.chargePointConnectors.forEach(cpc => {
        if (cpc.connectedVehicle == v.id) {
          v.chargePoint = cp;
          v.chargePointConnector = cpc;
        }
      });
    });
  });

  function formatDistanceDay(date) {
    const oneDay = 1000 * 3600 * 24;
    const distance = Date.now() - date.getTime();
    return formatDistanceToNow(date, { addSuffix: false }).replace('about ', '').replace('less than a', '1');
  }

  const initialRows = vehicles.map((vehicle, index) => {
    const schedules = [];
    vehicle['chargingSchedules'].forEach(cs => {
      schedules.push({ value: cs.id, label: cs.name });
    });

    var minutesUntilFull = (100 - vehicle.stateOfCharge) * 3;
    var hoursUntilFull = Math.floor(minutesUntilFull / 60);
    var timeUntilFull = "";
    if (hoursUntilFull == 0) {
      timeUntilFull = (minutesUntilFull % 60) + " mins";
    } else if (hoursUntilFull == 1) {
      timeUntilFull = hoursUntilFull + " hour, " + (minutesUntilFull % 60) + " mins";
    } else {
      timeUntilFull = hoursUntilFull + " hours, " + (minutesUntilFull % 60) + " mins";
    }

    return {
      vehicle: vehicle,
      id: vehicle.id,
      name: vehicle.name,
      status: vehicle.status,
      chargePointConnector: vehicle.chargePointConnector,
      currentChargingSchedule: vehicle.currentChargingSchedule,
      depotState: vehicle.depotState === "within_depot" ? "Depot" : "Running",
      lastPositionAt: vehicle.lastPositionAt,
      displayName: vehicle.displayName,
      stateOfCharge: vehicle.stateOfCharge,
      connected: vehicle.chargePointConnector?.state,
      current_draw: vehicle.current_draw,
      current_limit: vehicle.current_limit,
      x: vehicle.x,
      y: vehicle.y,
      schedules: schedules,

      lastChargeAgo: vehicle.lastBatteryCycle?.startedAt ?
        formatDistanceDay(new Date(vehicle.lastBatteryCycle.finishedAt), { addSuffix: true }) :
        null,
      lastChargePercs: vehicle.lastBatteryCycle?.startedAt ?
        vehicle.lastBatteryCycle.lowestStateOfCharge + " - " + vehicle.lastBatteryCycle.highestStateOfCharge :
        null,
      cpName: vehicle.chargePoint?.name && vehicle.chargePointConnector?.connectorId ?
        `${vehicle.chargePoint.name}:${vehicle.chargePointConnector.connectorId}` :
        null,
      timeToFull: vehicle.stateOfCharge === 100 && ["charging", "manual_charging", "connected"].includes(vehicle.chargePointConnector?.state) ?
        "Full" :
        vehicle.stateOfCharge !== null && ["charging", "manual_charging"].includes(vehicle.chargePointConnector?.state) ?
          timeUntilFull :
          null,
    };

  });

  const columns = [
    // { field: 'vehicle', headerName: 'ID', width: 70, editable: false, 
    //   renderCell: (params) => (
    //     <span>{params.value.displayName}</span>
    //   ),
    //   cellClassName: (params) => {
    //     return clsx('super-app', {
    //       // green: params.value.ocppControl,
    //       grey: !params.value.ocppControl,
    //     });
    //   }
    // },
    {
      field: 'displayName', headerName: 'Name', width: 100, editable: false,
      renderCell: (params) => (
        <span>{params.value}</span>
      ),
      cellClassName: (params) => {
        return clsx('super-app', {
          // green: params.value.ocppControl,
          white: params.row.vehicle.ocppControl,
          grey: !params.row.vehicle.ocppControl,
        });
      }
    },

    {
      field: 'depotState',
      headerName: 'Location',
      width: 100,
      editable: false,
      cellClassName: (params) => {
      const oneDay = 1000 * 3600 * 24;
      const distance = Date.now() - new Date(params.row.lastPositionAt).getTime();
      return clsx('super-app', {
        grey: params.row.lastPositionAt == null || distance > oneDay,
      });
      },
      renderCell: (params) => {
      const oneDay = 1000 * 3600 * 24;
      const distance = Date.now() - new Date(params.row.lastPositionAt).getTime();
      const isGrey = params.row.lastPositionAt == null || distance > oneDay;
      const lastSeen = params.row.lastPositionAt == null ? "never" : formatDistanceToNow(new Date(params.row.lastPositionAt), { addSuffix: true });
      return isGrey ? (
        <Tooltip title={`Last seen: ${lastSeen}`}>
        <span>{params.value}</span>
        </Tooltip>
      ) : (
        <span>{params.value}</span>
      );
      }
        },
        {
      field: 'stateOfCharge', headerName: 'SoC', width: 50, editable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          green: params.value >= 90,
          red: params.value < 90,
        });
      },
    },

    { field: 'lastChargeAgo', headerName: 'Charged', width: 100, editable: false },
    { field: 'lastChargePercs', headerName: 'Delta', width: 95, editable: false, description: "Last charge session percentage change" },
    {
      field: 'connected',
      headerName: 'Connected',
      width: 100,
      renderCell: (params) => {
        let color = "green";

        let minutesOrange = 4;      // After this time, the icon turns orange
        let minutesGray = 8;        // After this time, the icon turns gray

        // For Connected, use ChargePoint last_meter_values, for Charging, use ChargePointConnector last_meter_values
        // When they first change to Charging, they turn orange briefly. Not ideal.
        const { updated_at } = params.row.vehicle.chargePointConnector || {};
        let { lastMeterValues } = {};
        let { lastStatusNotification } = {};
        let { lastMessageTimestamp } = {};

        if (params.value === "connected" || params.value === "manual_connected" || (Date.now() - new Date(updated_at || 0).getTime()) < 60 * 1000)  {
          lastMeterValues = params.row.vehicle.chargePoint?.ocppState?.last_meter_values;
        } else if (params.value === "charging" || params.value === "manual_charging") {
          lastMeterValues = params.row.vehicle.chargePointConnector?.ocppState?.last_meter_values;
        }
        lastStatusNotification = params.row.vehicle.chargePointConnector?.ocppState?.last_status_notification;
        lastMessageTimestamp = params.row.vehicle.chargePointConnector?.ocppState?.last_message_timestamp;

        if (!updated_at && params.row.vehicle.chargePointConnector) {
          console.log("No updated_at", params.row.vehicle.chargePointConnector);
        }

        const mostRecentUpdate = new Date(Math.max(new Date(updated_at || 0), 
                                                   new Date(lastMeterValues || 0), 
                                                   new Date(lastStatusNotification || 0),
                                                   new Date(lastMessageTimestamp || 0)));

        let oldText = ": " + formatDistanceToNow(mostRecentUpdate, { addSuffix: true });

        if ((Date.now() - mostRecentUpdate.getTime()) > minutesGray * 60 * 1000) {
          color = "gray";
        } else if ((Date.now() - mostRecentUpdate.getTime()) > minutesOrange * 60 * 1000) {
          color = "#ffc433";
        }

        switch (params.value) {
          case "connected":
            return (
              <Tooltip title={`Connected${oldText}`}>
                <PowerIcon style={{ color: color }} />
              </Tooltip>
            );
          case "charging":
          case "manual_charging":
            return (
              <Tooltip title={`${params.value === "charging" ? "Charging" : "Manually Charging"}${oldText}`}>
                <BoltIcon className="animate-bolt" style={{ color: color }} />
                {params.value === "manual_charging" && "M"}
              </Tooltip>
            );
          case "manual_connected":
            return (
              <Tooltip title={`Manually Connected${oldText}`} >
                <PowerIcon style={{ color: color }} />M
              </Tooltip>
            );
          default:
            return <Icon style={{ color: color }} />;
        }
      },
      editable: false,
    },
    { field: 'timeToFull', headerName: 'Time To Full', width: 155, editable: false },
    {
      field: 'cpName',
      headerName: 'Charge Point',
      width: 150,
      editable: false,
      renderCell: (params) => (
        // console.log(params),
        <span>
          {params.value}
        </span>
      ),
      // renderCell: (params) => (
      //   console.log(params),
      //   <span>
      //     {params.value.chargePoint?.name && params.value.chargePointConnector?.connectorId ?
      //       `${params.value.chargePoint.name}:${params.value.chargePointConnector.connectorId}` :
      //       null}
      //   </span>
      // ),
      cellClassName: (params) => {
        // console.log(params.row.vehicle);
        return clsx('super-app', {
          white: params.row.vehicle.chargePoint?.ocppControl,
          white: !params.row.vehicle.chargePoint,
          grey: !params.row.vehicle.chargePoint?.ocppControl,
        });
      }
    },
    {
      field: 'schedules',
      headerName: 'Assigned Schedules',
      width: 150,
      type: "singleSelect",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value.map((element) => (
            <Chip
              key={element.value}
              label={element.label}
              variant="outlined"
            />
          ))}
        </>
      ),
    },
  ];

  // Map the vehicles data to rows for the DataGrid
  return (
    <Box style={{ height: "100%", width: '100%' }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'displayName', sort: 'asc' }],
          },
        }}
        rowHeight={34}
        rows={initialRows}
        columns={columns}
        // pageSize={10}
        rowsPerPageOptions={[]}
        disableSelectionOnClick
        editMode="row"
        // rowModesModel={rowModesModel}
        // onRowModesModelChange={handleRowModesModelChange}
        // slotProps={{
        //   toolbar: { setRows, setRowModesModel },
        // }}
        onRowClick={(params, event) => {
          console.log("onRowClick", params, event);
          navigate(`/vehicle/charging/${params.id}`);
        }}
        sx={{
          '& .super-app.green': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
          },
          '& .super-app.red': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
          },
          '& .super-app.grey': {
            backgroundColor: '#e0e0e0', // lighter grey
            // color: '#grey',
            // fontWeight: '600',
          },
          '& .super-app.white': {
            backgroundColor: 'white',
          },
        }} />
    </Box>
  );
}


export { ChargingVehicleList };




// https://github.com/mui/mui-x/issues/4410