const depots_query = `
  query Depots {
    depots {
      id
      name
      geofence
    }
  }
`;

const charge_logs_query = `
  query ChargeLogs($depot: ID!) {
    depot(id: $depot) {
      id
      name
      chargeLogs { 
            id
            chargeStart
            chargeEnd
            startSoc
            endSoc
            chargeCompleted
            createdAt
            updatedAt
            vehicle { id identifier }
            chargePointConnector { id connectorId chargePoint { id name } }
            meterStart
            meterEnd
            kwhDelivered
            transactionId
            chargeType
      }
    }
  }
`;

// chargeLogs {
//   id
//   chargeStart
//   chargeEnd
//   startSoc
//   endSoc
//   chargeCompleted
//   createdAt
//   updatedAt
//   vehicle { id }
//   chargePoint { id }
//   chargePoint { id }
//   # chargingSchedule { id }
//   meterStart
//   meterEnd
// }

export { depots_query, charge_logs_query };
