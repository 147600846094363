// The connection context is used to provide global access to 'connection state' 
// based on the outcome of queries to the database. While a query is still loading,
// or if a query fails, the connection can be updated with this status which allows us 
// to display to users whether the app is currently getting live data.


import React, { createContext, useContext, useReducer } from "react";

/**
 * Reducer function to return the connection state.
 * Used to update global connection state when an object fails to load
 * @param {Object} state - The current state of the connection.
 * @param {Object} action - The action to be performed on the state.
 * @returns {Object} The new state after applying the action.
 */
function connection_reducer(state, action) {
  switch (action.type) {
    case "SET_CONN_STATUS":
      return {
        ...state,
        connected: action.connected,
      };
    case "SET_CONN_ERROR":
      return {
        ...state,
        connected: false
      }
    case "SET_CONN_SUCCESS":
      return {
        ...state,
        connected: true
      }
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
};

const initial_state = {
  connected: true
};

/**
 * Context to provide connection state and dispatch function.
 */
const ConnectionContext = createContext();

/**
 * Provider component to wrap parts of the app that need access to connection state.
 * @param {Object} props - The properties passed to the provider component.
 * @returns {JSX.Element} The provider component with connection context.
 */
function ConnectionContextProvider(props) {
  const [state, dispatch] = useReducer(connection_reducer, initial_state);

  return (
    // State is the current connection state, and dispatch takes an action and updates the state
    <ConnectionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ConnectionContext.Provider>
  );
};

/**
 * Custom hook to access the connection context.
 * @returns {Object} The connection context value.
 * @throws Will throw an error if used outside of a ConnectionContextProvider.
 */
function useConnection() {
  const context = useContext(ConnectionContext);
  if (context === undefined) throw new Error('useConnection must be used within ConnectionProvider')

  return context;
}

export { ConnectionContextProvider, ConnectionContext, useConnection };
